@font-face {
  font-family: "Klavika";
  src: url("../fonts/klavika-bold.otf"), url("../fonts/Klavika-BoldItalic.otf"), url("../fonts/klavika-light.otf"),
  url("../fonts/Klavika-LightItalic.otf"), url("../fonts/klavika-medium.otf"), url("../fonts/Klavika-MediumItalic.otf"),
  url("../fonts/Klavika-Regular.otf"), url("../fonts/Klavika-RegularItalic.otf");
}

body {
  font-family: "Klavika", serif;
}
