@font-face {
  font-family: Klavika;
  src: url("klavika-bold.22655456.otf"), url("Klavika-BoldItalic.38775ef6.otf"), url("klavika-light.0d6b4132.otf"), url("Klavika-LightItalic.10a68880.otf"), url("klavika-medium.b697b04a.otf"), url("Klavika-MediumItalic.4f94407a.otf"), url("Klavika-Regular.984bcb60.otf"), url("Klavika-RegularItalic.663e1682.otf");
}

body {
  font-family: Klavika, serif;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.azan-wrapper {
  width: 100%;
  height: 100%;
  background-image: url("vrstva.9ef3b03e.png"), url("farmer.3187b7ca.png"), url("jumpdog.ed4f4cbc.png"), url("recycle.1c194cbe.png"), url("las_right_top.13d7527e.png"), url("las_right.d105c85f.png"), url("dog.364a9583.png"), url("las_left.4b1f17f8.png"), url("background.1da81085.png");
  background-position: right top 1332px, right top 1675px, 0 1285px, 0 1636px, right top 52px, right top 465px, 0 0, 0 385px, 0 0;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 267px, 327px, 486px, 486px, 730px, 569px, 405px, 356px, cover;
  flex-direction: column;
  display: flex;
  position: relative;
}

.image {
  width: 161px;
  margin-top: 52px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.imageBio {
  width: 235px;
  position: absolute;
  top: 71px;
  right: 25%;
}

.content {
  flex-direction: column;
  align-items: center;
  margin: 0 32px;
  display: flex;
}

.headerTitle {
  color: #a2bf37;
  max-width: 590px;
  text-align: center;
  margin-top: 25px;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.5px;
}

.headerDescription {
  max-width: 590px;
  text-align: center;
  color: #747272;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.cards {
  margin-top: 38px;
  display: flex;
}

.card {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.cardImage {
  width: 274px;
  margin-top: 20px;
}

.cardLogo {
  height: 90px;
}

.cardTitle {
  text-align: center;
  margin-top: 9px;
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
}

.cardColor1 {
  color: #95c11e;
}

.cardColor2 {
  color: #0098a8;
}

.cardColor3 {
  color: #e65300;
}

.cardDescription {
  color: #757373;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}

.cardButton {
  color: #fff;
  border: unset;
  cursor: pointer;
  -o-transition: .3s;
  background-color: #757474;
  border-radius: 15px;
  margin-top: 20px;
  padding: 12px 24px;
  transition: all .3s;
}

.cardButton:hover {
  background-color: #757474cc;
}

.cardButtonImage {
  width: 14px;
  font-family: Titillium Web;
  font-size: 15.4983px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.foods {
  justify-content: center;
  align-items: center;
  margin-top: 83px;
  display: flex;
}

.column {
  flex-direction: column;
}

.foodsImage {
  width: 292px;
  margin-top: 15px;
}

.food {
  width: 315px;
  text-align: center;
  margin: 0 16px;
}

.margintTop {
  margin-top: 36px;
}

.foodTitle {
  text-align: center;
  color: #a2bf37;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  display: flex;
}

.foodDescription {
  text-align: center;
  color: #747272;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 120%;
  display: flex;
}

.insect {
  flex-direction: column;
  align-items: center;
  margin-top: 83px;
  display: flex;
}

.insectTitle {
  text-align: center;
  color: #94c01e;
  align-items: center;
  font-size: 46.3448px;
  font-weight: 700;
  line-height: 100%;
  display: flex;
}

.insectHighlight {
  color: #fff;
  justify-content: center;
  padding: 12px 22px;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  position: relative;
}

.insectSvg {
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 9px;
  left: 0;
  right: 0;
}

.insectSpan {
  z-index: 2;
  position: relative;
}

.insectDescription {
  text-align: center;
  color: #747272;
  max-width: 700px;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
}

.insectDescriptionH {
  color: #94c01e;
  font-weight: 900;
}

.co2 {
  width: 172px;
  margin-top: 50px;
}

.carbon {
  width: 100%;
  text-align: center;
}

.imageBio2 {
  width: 235px;
  margin-top: 15px;
  display: none;
}

.insectSpan {
  width: 560px;
  text-align: center;
  background-image: url("bar3.a5d9474b.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 6px 24px 10px;
  display: block;
}

@media (max-width: 1300px) {
  .imageBio {
    display: none;
  }

  .imageBio2 {
    display: block;
  }
}

@media (max-width: 480px) {
  .azan-wrapper {
    width: 100%;
    height: 100%;
    background-image: url("recycle.1c194cbe.png"), url("las_left.4b1f17f8.png"), url("background.1da81085.png");
    background-position: 0 1636px, 0 385px, 0 0;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 486px, 356px, cover;
    flex-direction: column;
    display: flex;
    position: relative;
  }

  .cards {
    flex-direction: column;
  }

  .card {
    margin-top: 30px;
  }

  .foods {
    flex-direction: column;
  }

  .food, .imageRight {
    margin-top: 15px;
  }

  .insectSpan {
    width: auto;
    height: 70px;
    text-align: center;
    background-image: url("bar2.80959fd4.png");
    background-size: contain;
  }

  .insectHighlight {
    text-align: center;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 120%;
    display: flex;
  }
}

.testers {
  text-align: center;
  max-width: 590px;
  border: 2px solid #a2bf37;
  border-radius: 5px;
  margin: 83px 0;
  padding: 12px;
}

.testers .testersHeader {
  color: #a2bf37;
  font-size: 36px;
  font-weight: 700;
}

.testers .testersDescription {
  text-align: center;
  color: #747272;
  font-size: 18px;
}

.testers a {
  color: inherit;
  text-decoration: underline;
}

.testers .subscribeContainer span {
  cursor: pointer;
}

.cardButton.disabled {
  cursor: not-allowed;
  background-color: #e3e3e3;
}

.testersLink {
  max-width: 590px;
  color: #747272;
  text-align: center;
  border: 2px solid #a2bf37;
  border-radius: 5px;
  margin-top: 83px;
  padding: 12px 20px;
  font-size: 22px;
}

.testersLink .testersGoTo {
  cursor: pointer;
  margin-top: 15px;
}

.testersLink .testersGoTo:hover {
  color: #a2bf37;
}

.imageLeft, .imageRight {
  width: 225px;
  position: relative;
  top: -35px;
}

@media (max-width: 1300px) {
  .imageLeft, .imageRight {
    top: -65px;
  }
}

@media (max-width: 480px) {
  .imageLeft, .imageRight {
    top: 0;
  }

  .foodsPackages {
    margin-top: 60px;
  }

  .testersLink {
    margin-top: 70px;
  }

  .azan-wrapper {
    background-image: url("las_left.4b1f17f8.png"), url("background.1da81085.png");
    background-position: 0 385px, 0 0;
    background-repeat: no-repeat, no-repeat;
    background-size: 356px, cover;
  }
}

/*# sourceMappingURL=index.5bf70680.css.map */
