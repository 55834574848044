

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}
.azan-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;


  background-image:  url("../assets/vrstva.png"), url("../assets/farmer.png"), url("../assets/jumpdog.png"), url("../assets/recycle.png"), url("../assets/las_right_top.png"), url("../assets/las_right.png"), url("../assets/dog.png"), url("../assets/las_left.png"), url("../assets/background.png");
  background-repeat:  no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top 1332px right, top 1675px right, top 1285px left, top 1636px left, top 52px right, top 465px right, top left, top 385px left, top left;
  background-size: 267px, 327px, 486px, 486px, 730px, 569px, 405px, 356px, cover;
}

.image {
  width: 161px;
  margin-top: 52px;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imageBio {
  position: absolute;
  top: 71px;
  right: 25%;
  width: 235px;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 32px;
}

.headerTitle {
  color: #A2BF37;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.5px;
  margin-top: 25px;
  max-width: 590px;
  text-align: center;
}

.headerDescription {
  margin-top: 16px;
  max-width: 590px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #747272;
}

.cards {
  margin-top: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cardImage {
  width: 274px;
  margin-top: 20px;
}

.cardLogo {
  height: 90px;
}

.cardTitle {

  line-height: 36px;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
  margin-top: 9px;
}

.cardColor1 {
  color: #95C11E;
}

.cardColor2 {
  color: #0098A8;
}

.cardColor3 {
  color: #E65300;
}

.cardDescription {
  color: #757373;
  line-height: 18px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.cardButton {
  background-color: #757474;
  color: white;
  border: unset;
  border-radius: 15px;
  padding: 12px 24px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-top: 20px;
}

.cardButton:hover {
  background-color: rgba(117, 116, 116, 0.8)
}

.cardButtonImage {
  width: 14px;

  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 15.4983px;
  line-height: 100%;
}

.foods {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 83px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.foodsImage {
  width: 292px;
  margin-top: 15px;
}

.food {
  margin: 0 16px;
  width: 315px;
  text-align: center;
}

.margintTop {
  margin-top: 36px;
}

.foodTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;

  color: #A2BF37;
}

.foodDescription {
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #747272;
}

.insect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 83px;
}

.insectTitle {
  font-weight: 700;
  font-size: 46.3448px;
  line-height: 100%;
  /* or 46px */

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;

  color: #94C01E;
}

.insectHighlight {
  color: white;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  padding: 12px 22px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.insectSvg {
  position: absolute;
  left: 0;
  right:0;
  top: 9px;
  width: 100%;
  z-index: 1;
}

.insectSpan {
  position: relative;
  z-index: 2;
}

.insectDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */
  text-align: center;

  color: #747272;
  max-width: 700px;
}

.insectDescriptionH {
  font-weight: 900;
  color: #94C01E;
}

.co2 {
  width: 172px;
  margin-top: 50px;
}
.carbon {
  width: 100%;
  text-align: center;
}
.imageBio2 {
  display: none;
  width: 235px;
  margin-top: 15px;
}

.insectSpan {
  background-image: url("../assets/bar3.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  padding: 6px 24px 10px;
  width: 560px;
  display: block;
  text-align: center;
}

@media (max-width: 1300px) {
  .imageBio {
    display: none;
  }

  .imageBio2 {
    display: block;
  }
}

@media (max-width: 480px) {
  .azan-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;


    background-image:  url("../assets/recycle.png"), url("../assets/las_left.png"), url("../assets/background.png");
    background-repeat:  no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top 1636px left, top 385px left, top left;
    background-size: 486px, 356px, cover;
  }

  .cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .card {
    margin-top: 30px;
  }

  .foods {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .food {
    margin-top: 15px;
  }

  .imageRight {
    margin-top: 15px;
  }

  .insectSpan {
    background-image: url("../assets/bar2.png");
    width: auto;
    height: 70px;
    background-size: contain;
    text-align: center;
  }

  .insectHighlight {
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    /* or 34px */

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
}
