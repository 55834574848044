// fonts were originally present in index.html
@import "font";

// upstream.css contains styles copied from nowybritcare.karmybrit.pl
@import "upstream";

// this file contains custom styles added for purposes of this LP
.testers {
  text-align: center;
  margin: 83px 0;
  max-width: 590px;
  padding: 12px;
  border: 2px solid #a2bf37;
  border-radius: 5px;

  .testersHeader {
    color: #a2bf37;
    font-size: 36px;
    font-weight: 700;
  }

  .testersDescription {
    text-align: center;
    color: #747272;
    font-size: 18px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  .subscribeContainer span {
    cursor: pointer;
  }
}

.cardButton.disabled {
  background-color: scale-color(#757474, $lightness: 80%);
  cursor: not-allowed;
}

.testersLink {
  max-width: 590px;
  padding: 12px 20px;
  border: 2px solid #a2bf37;
  border-radius: 5px;
  color: #747272;
  font-size: 22px;
  text-align: center;
  margin-top: 83px;

  .testersGoTo {
    cursor: pointer;
    margin-top: 15px;

    &:hover {
      color: #a2bf37;
    }
  }
}

.imageLeft, .imageRight {
  width: 225px;
  position: relative;
  top: -35px;
}

@media (max-width: 1300px) {
  .imageLeft, .imageRight {
    top: -65px;
  }
}

@media (max-width: 480px) {
  .imageLeft, .imageRight {
    top: 0;
  }

  .foodsPackages {
    margin-top: 60px;
  }

  .testersLink {
    margin-top: 70px;
  }

  .azan-wrapper {
    background-image: url("../assets/las_left.png"), url("../assets/background.png");
    background-repeat: no-repeat, no-repeat;
    background-position: top 385px left, top left;
    background-size: 356px, cover;
  }
}